import React, { useState, useEffect, useRef } from "react";
import WebLogo from "../assets/images/unpwnableThings-Logo.png";
import { motion } from "framer-motion";

import "../styles/WHeader.css";

const WNavigate = () => {
  const [activeLink, setActiveLink] = useState("Home");
  const [showMenu, setShowMenu] = useState(false);
  const btRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClick = (e) => {
      if (
        btRef.current &&
        !btRef.current.contains(e.target) &&
        menuRef.current &&
        !menuRef.current.contains(e.target)
      ) {
        setShowMenu(false);
      }
    };
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const handleUserClick = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav className={`z-10 transition duration-400 ease-in-out w-full`}>
      <div>
        <div
          className={`${
            showMenu
              ? "flex-col fixed top-0 left-0 right-0 w-full z-30 origin-top animate-open-menu mobile-header-nav "
              : "hidden"
          }`}
        >
          <button
            ref={btRef}
            type="button"
            onClick={handleUserClick}
            className="inline-flex items-center justify-center p-2 w-10 h-10 ml-3 text-sm text-gray-500 rounded-lg absolute top-5 right-7"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5 text-black"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <motion.a
            transition={{ duration: 0.6 }}
            initial={{ x: "90%", y: "50%", scale: 1 }}
            animate={{ x: "10%", y: "0%", scale: 1 }}
            href="/"
            className="flex items-center absolute left-0 top-5"
          >
            <img src={WebLogo} className="h-10 mr-3" alt="Pwnsters Logo" />
          </motion.a>
          {/*<hr class="h-px my-8 bg-gray-700 opacity-25 w-full border-0 dark:bg-gray-700 fixed top-12"></hr>*/}
          <nav
            className="flex flex-col min-h-screen items-center py-8"
            aria-label="mobile"
          >
            <a
              href="https://unpwnable.io"
              className="w-full text-center py-6 text-black text-3xl mt-20"
            >
              Home
            </a>
          </nav>
        </div>
        <div
          className={`flex md:hidden items-center justify-between font-bold font-WGetLinkFont ${
            showMenu ? "hidden" : "py-5 flex"
          } `}
        >
          <motion.a
            transition={{ duration: 0.6 }}
            initial={{ x: "90%", y: "50%", scale: 1 }}
            animate={{ x: "10%", y: "0%", scale: 1 }}
            href="/"
            className="flex items-center"
          >
            <img src={WebLogo} className="h-10 mr-3" alt="Pwnsters Logo" />
          </motion.a>
          <motion.div
            className="flex items-center md:hidden mr-20"
            initial={{ x: "0%", y: "0%" }}
            animate={{ x: "100%", y: "0%" }}
            transition={{ duration: 0.4 }}
          >
            <button
              ref={btRef}
              type="button"
              onClick={handleUserClick}
              className="inline-flex items-center justify-center p-2 w-10 h-10 ml-3 text-sm text-gray-500 rounded-lg"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
            <div ref={menuRef} className="w-full" id="navbar-hamburger">
              <div
                className={`absolute top-0 right-0 w-full ${
                  showMenu
                    ? "block w-[100vw] h-[100vh]"
                    : "hidden"
                } z-20`}
              >
                <ul className="flex flex-col font-medium rounded-lg sm:flex-row sm:space-x-8 sm:mt-0 sm:border-0 w-full h-full">
                  <li>
                    <a href="https://unpwnable.io/" className="">
                      Home
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <div
        className={`py-5 items-center justify-between tablet:justify-between font-bold font-WGetLinkFont hidden md:flex`}
      >
        <motion.a
          transition={{ duration: 0.6 }}
          initial={{ x: "90%", y: "50%", scale: 1 }}
          animate={{ x: "10%", y: "0%", scale: 1 }}
          href="/"
          className="flex items-center"
        >
          <img src={WebLogo} className="h-10 mr-3" alt="Pwnsters Logo" />
        </motion.a>
        <motion.div
          className="items-center md:flex hidden"
          initial={{ x: "0%", y: "0%" }}
          animate={{ x: "-50%", y: "0%" }}
          transition={{ duration: 0.4 }}
        >
          <ul className="flex flex-col font-medium border rounded-lg sm:flex-row sm:space-x-8 sm:mt-0 sm:border-0 ">
            <li>
              <a href="https://unpwnable.io/" className="mr-16">
                Home
              </a>
            </li>
          </ul>
        </motion.div>
      </div>
    </nav>
  );
};

export default WNavigate;
