// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradiant-01 {
  position: absolute;
  width: 500px;
  height: 500px;
  left: 35%;
  top: 35%;

  background: rgba(5, 96, 156, 0.451);
  filter: blur(120px);
}

.gradiant-02 {
  position: absolute;
  width: 300px;
  height: 400px;
  right: 10%;
  top: 10%;

  background: rgba(43, 170, 255, 0.451);
  filter: blur(120px);
}

.input-form {
  /* From https://css.glass */
  background: #0000009e;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.1px);
  -webkit-backdrop-filter: blur(6.1px);
  border: 1px solid rgba(0, 0, 0, 1);
}

/* .eye {
  position: absolute;
  width: 80px;
  height: 80px;
  display: block;
  background-color: #fff;
  margin: 0 15px;
  border-radius: 50%;
} */

.eyes-left {
  left: calc(50% - 130px);
}

.eyes-right {
  right: calc(50% - 130px);
}

.eye-black::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  left: 10px;
  top: calc(50% - 10px);
}
`, "",{"version":3,"sources":["webpack://./src/styles/WGetLinkUser.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,SAAS;EACT,QAAQ;;EAER,mCAAmC;EACnC,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,UAAU;EACV,QAAQ;;EAER,qCAAqC;EACrC,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB;EACrB,mBAAmB;EACnB,yCAAyC;EACzC,4BAA4B;EAC5B,oCAAoC;EACpC,kCAAkC;AACpC;;AAEA;;;;;;;;GAQG;;AAEH;EACE,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;EAClB,UAAU;EACV,qBAAqB;AACvB","sourcesContent":[".gradiant-01 {\n  position: absolute;\n  width: 500px;\n  height: 500px;\n  left: 35%;\n  top: 35%;\n\n  background: rgba(5, 96, 156, 0.451);\n  filter: blur(120px);\n}\n\n.gradiant-02 {\n  position: absolute;\n  width: 300px;\n  height: 400px;\n  right: 10%;\n  top: 10%;\n\n  background: rgba(43, 170, 255, 0.451);\n  filter: blur(120px);\n}\n\n.input-form {\n  /* From https://css.glass */\n  background: #0000009e;\n  border-radius: 16px;\n  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\n  backdrop-filter: blur(6.1px);\n  -webkit-backdrop-filter: blur(6.1px);\n  border: 1px solid rgba(0, 0, 0, 1);\n}\n\n/* .eye {\n  position: absolute;\n  width: 80px;\n  height: 80px;\n  display: block;\n  background-color: #fff;\n  margin: 0 15px;\n  border-radius: 50%;\n} */\n\n.eyes-left {\n  left: calc(50% - 130px);\n}\n\n.eyes-right {\n  right: calc(50% - 130px);\n}\n\n.eye-black::after {\n  content: \"\";\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background-color: white;\n  position: absolute;\n  left: 10px;\n  top: calc(50% - 10px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
