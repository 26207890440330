import React from "react";

const WNotFound = () => {
  return (
    <div className="h-[10vh] w-[100vw] flex-inline justify-center items-center">
      <h2 className="font-bold text-3xl w-full flex items-center justify-center my-10">404 Not Found</h2>
      <div className="border-t border-[#333]"></div>
      <h3 className="w-full flex items-center justify-center tetx-xs my-3">Unpwnable Things</h3>
    </div>
  );
};

export default WNotFound;
