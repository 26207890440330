import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../styles/WGetLinkUser.css";
import Griffon from "../assets/images/griffon.png";
import Pwnster from "../assets/images/pwnsters.png";

import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const WGetLinkUser = () => {
  const textInputRef = useRef(null);

  const [textInput, setTextInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isIphone, setIsIphone] = useState(false);
  const [telegramOrTwitter, setTelegramOrTwitter] = useState("");
  const [emailText, setEmailText] = useState("");
  const [isValidTelegram, setIsValidTelegram] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(null);

  const [eyeRotation, setEyeRotation] = useState({ left: 0, right: 0 });

  const createNotification = (type) => {
    return () => {
      switch (type) {
        case "info":
          NotificationManager.info("Info message");
          break;
        case "success":
          NotificationManager.success("Success message", "Title here");
          break;
        case "warning":
          NotificationManager.warning(
            "Warning message",
            "Close after 3000ms",
            3000
          );
          break;
        case "error":
          NotificationManager.error("Error message", "Click me!", 5000, () => {
            alert("callback");
          });
          break;
      }
    };
  };

  const handleTextInputChange = (e) => {
    setTextInput(e.target.value); // Update the state with the input value
  };

  const handleTelegramOrTwitterChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input value starts with '@'
    const validTelegramId = /^$|^@[a-zA-Z][a-zA-Z0-9_]{4,}$/;
    const isValidTelegram = validTelegramId.test(inputValue);

    if (!inputValue.startsWith("@")) {
      // If it doesn't, add '@' to the beginning
      setTelegramOrTwitter("@" + inputValue);
    } else if (inputValue === "@") {
      // If it starts with '@' but the rest is empty, remove the '@'
      setTelegramOrTwitter("");
    } else {
      // If it does, update the state without any changes
      setTelegramOrTwitter(inputValue);
    }
    if (isValidTelegram) {
      setIsValidTelegram(true);
    } else if (inputValue === "@") {
      setIsValidTelegram(null);
    } else {
      setIsValidTelegram(false);
    }
  };

  const handleEmailChange = (e) => {
    const MailText = e.target.value;
    setEmailText(e.target.value);

    const emailFormat =
      /^$|^[a-zA-Z0-9_.+]*[a-zA-Z][a-zA-Z0-9_.+]*@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const isValidEmail = emailFormat.test(MailText);

    if (MailText === "") {
      setIsValidEmail(null);
    } else if (isValidEmail) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the form from submitting and refreshing the page
    setLoading(true); // Show loading indicator

    // Make an API call to send the text to the backend
    if (
      (isValidTelegram || isValidTelegram === null) &&
      (isValidEmail || isValidEmail === null) &&
      textInput
    ) {
      axios
        .post("https://pwnsters.unpwnable.io/api/v1/userThreat", {
          text: textInput,
          email: emailText,
          telegramOrTwitter: telegramOrTwitter,
        })
        .then((response) => {
          // Handle the response from the backend if needed
          console.log("Data sent to the backend:", response.data);
          // setSuccess(true);
          NotificationManager.success(
            "Success message",
            "Your message has been successfully sent."
          );
        })
        .catch((error) => {
          // Handle any errors that occur during the API call
          console.error("Error sending data to the backend:", error);
          // setSuccess(false);
          NotificationManager.error(
            "Error message",
            "Something went wrong!",
            5000,
            () => {
              alert("callback");
            }
          );
        })
        .finally(() => {
          setLoading(false); // Hide loading indicator
        });
    } else {
      if (!isValidEmail && isValidEmail !== null) {
        NotificationManager.error(
          "Error message",
          "Fill the email input correctly!",
          5000,
          () => {
            alert("callback");
          }
        );
      } else if (!isValidTelegram && isValidTelegram !== null) {
        NotificationManager.error(
          "Error message",
          "Fill the telegram or twitter id correctly!",
          5000,
          () => {
            alert("callback");
          }
        );
      } else {
        NotificationManager.error(
          "Error message",
          "Threat input is empty!",
          5000,
          () => {
            alert("callback");
          }
        );
      }
      setLoading(false);
    }
  };

  const handleEyeMotivate = (event) => {
    const eyes = document.querySelectorAll(".eye-black");

    eyes.forEach((eye) => {
      const eyeBounds = eye.getBoundingClientRect();
      const eyeCenterX = eyeBounds.left + eyeBounds.width / 2;
      const eyeCenterY = eyeBounds.top + eyeBounds.height / 2;

      const angle = Math.atan2(
        event.clientX - eyeCenterX,
        event.clientY - eyeCenterY
      );
      const angleDeg = (angle * 180) / Math.PI;

      if (eye.classList.contains("eye-left")) {
        setEyeRotation((prevRotation) => ({
          ...prevRotation,
          left: -angleDeg,
        }));
      } else if (eye.classList.contains("eye-right")) {
        setEyeRotation((prevRotation) => ({
          ...prevRotation,
          right: -angleDeg,
        }));
      }
    });
  };

  useEffect(() => {
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (iOS) {
      setIsIphone(true);
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1200) {
      setIsIphone(true);
    }
  }, []);

  useEffect(() => {
    if (textInputRef.current) {
      textInputRef.current.focus();
      textInputRef.current.setSelectionRange(0, 0);
    }
  }, []);

  useEffect(() => {
    if (!isIphone) {
      document.body.addEventListener("mousemove", handleEyeMotivate);

      return () => {
        document.body.removeEventListener("mousemove", handleEyeMotivate);
      };
    }
  }, [eyeRotation, isIphone]);

  return (
    <div
      className="lg:h-[100vh] md:h-[110vh] h-[130vh] py-16 tablet:px-16 bg-[#ffffff] font-WGetLinkFont"
      id="GetLink"
    >
      <h2 className="text-3xl font-bold sm:ml-16 mx-5 flex text-[#285434]">
        Share Your Threat With The Community
      </h2>
      <div className="relative flex justify-between items-center mx-auto mt-10">
        {!isIphone ? (
          <div className="hidden lg:block w-1/4 mx-auto relative">
            <img
              className="eyes hover:scale-105 transition duration-200 "
              src={Griffon}
              alt=""
            ></img>
            <div className="eye eyes-left w-[65px] h-[105px] rounded-full absolute top-[15vh] left-[6vw]">
              <div
                className="eye-black eye-left w-[4vh] h-[4vh] rounded-full bg-black absolute bottom-5 left-3 transform origin-top"
                style={{ transform: `rotate(${eyeRotation.left}deg)` }}
              ></div>
            </div>

            <div className="eye eyes-right w-[65px] h-[105px] rounded-full absolute top-[15vh] left-[15.5vw]">
              <div
                className="eye-black eye-right w-[4vh] h-[4vh] rounded-full bg-black absolute bottom-5 left-3 transform origin-top"
                style={{ transform: `rotate(${eyeRotation.right}deg)` }}
              ></div>
            </div>
          </div>
        ) : (
          <div className="lg:hidden sm:block hidden">
            <img src={Pwnster} alt="pwnster"></img>
          </div>
        )}
        <div className="w-full lg:w-1/2 mx-auto">
          <div className="w-[90%] mx-auto">
            {/*<div className="gradiant-01 rounded-full sm:w-[500px] sm:h-[500px] w-[200px] h-[300px]  sm:block hidden"></div>
  <div className="gradiant-02 rounded-full sm:w-[500px] sm:h-[500px] w-[100px] h-[200px] sm:block hidden"></div>*/}

            <form onSubmit={handleSubmit} className="mx-auto">
              <label
                htmlFor="message"
                className="block md:px-20 mb-2 text-[#17271cc7] font-bold md:text-2xl text-lg justify-center"
              >
                If you are aware of any threats, you can explain it or share its
                link below.
              </label>
              <textarea
                id="message"
                rows="4"
                value={textInput}
                onChange={handleTextInputChange}
                className="block mx-auto p-5 w-[90%]  focus:outline-[#285434c7] outline-[#285434c7] border-[#6a8070c7] border-[1px] shadow-2xl text-black bg-[#8ad19fc7] rounded-2xl lg:mx-20 resize-none mt-10 h-[18rem] placeholder:text-[#666666]"
                placeholder="Write your threat here..."
              ></textarea>
              <div className="md:flex md:justify-center lg:ml-20 mx-2 md:mx-10 sm:ml-10 md:w-[90%]">
                <input
                  type="text"
                  value={telegramOrTwitter}
                  onChange={handleTelegramOrTwitterChange}
                  id="telegramOrTwitter"
                  className={`p-3  md:w-1/2 w-[95%] block mx-auto ${
                    isValidTelegram || isValidTelegram === null
                      ? "focus:outline-[#285434c7] outline-[#285434c7] border-[#6a8070c7] bg-[#8ad19fc7]"
                      : "focus:outline-[#5e2626c7] outline-[#54282bc7] border-[#806a6ac7] bg-[#f95b5bc7]"
                  } border-[1px] shadow-2xl text-black  rounded-2xl mt-2 placeholder:text-[#666666]`}
                  placeholder="Enter your Telegram or Twitter account"
                />
                <input
                  type="email"
                  value={emailText}
                  onChange={handleEmailChange}
                  id="email"
                  className={`p-3 md:w-1/2 w-[95%] block mx-auto ${
                    isValidEmail || isValidEmail === null
                      ? "focus:outline-[#285434c7] outline-[#285434c7] border-[#6a8070c7] bg-[#8ad19fc7]"
                      : "focus:outline-[#5e2626c7] outline-[#54282bc7] border-[#806a6ac7] bg-[#f95b5bc7]"
                  } lg:ml-2  border-[1px] shadow-2xl text-black rounded-2xl mt-2 placeholder:text-[#666666]`}
                  placeholder="Enter your email"
                />
              </div>
              <input
                type="submit"
                value={loading ? "Sending Message..." : "Send Message"}
                disabled={loading}
                className="input-form p-4 hover:cursor-pointer block mx-auto  my-8 md:w-64 w-auto active:border-[#00b333] active:outline-[#48ff79c7] active:bg-[#50a767c7] border-[#708576c7] text-white bg-[#265132c7]"
              />
            </form>
            <NotificationContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WGetLinkUser;
