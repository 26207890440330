import React, { useEffect } from "react";

import WHeader from "./components/WHeader";
import WGetLinkUser from "./components/WGetLinkUser";
import WShowPosts from "./components/WShowPosts";

import "./styles/App.css";

const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <WHeader />
      <WGetLinkUser />
      <WShowPosts />
    </div>
  );
};

export default App;
