// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .MuiLinearProgress-bar
  .MuiLinearProgress-barColorPrimary {
  background-color: black;
} */

.mobile-header-nav {
  /* From https://css.glass */
  background: rgba(44, 141, 165, 0.33);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
}
`, "",{"version":3,"sources":["webpack://./src/styles/WHeader.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;EACE,2BAA2B;EAC3B,oCAAoC;EACpC,yCAAyC;EACzC,4BAA4B;EAC5B,oCAAoC;AACtC","sourcesContent":["/* .MuiLinearProgress-bar\n  .MuiLinearProgress-barColorPrimary {\n  background-color: black;\n} */\n\n.mobile-header-nav {\n  /* From https://css.glass */\n  background: rgba(44, 141, 165, 0.33);\n  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\n  backdrop-filter: blur(9.5px);\n  -webkit-backdrop-filter: blur(9.5px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
