import React, { useEffect, useState, useRef } from "react";
import FiveLatesPosts from "./FiveLatesPosts";
import axios from "axios";
import TelegramIcon from "../assets/images/Telegram.png";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const WShowPosts = () => {
  const [posts, setPosts] = useState([]);
  const [otherPosts, setOtherPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [batchSize] = useState(5);
  const [allPosts, setAllPosts] = useState(0);
  const [hasMorePost, setHasMorePost] = useState(true);
  const [startIndex, setStartIndex] = useState(0);
  const postRef = useRef(null);

  const [fiveLatesPosts, setFiveLatesPosts] = useState([]);

  const getHostnameFromUrl = (urlString) => {
    try {
      if (urlString.includes("http://") || urlString.includes("https://")) {
      const url = new URL(urlString);
      return url.hostname;
    } else {
      return urlString;
    }
    } catch (error) {
      console.error('Invalid URL:', urlString);
      return '';
    }
  };
  const loadMorePosts = () => {
    if (startIndex >= allPosts) {
      setHasMorePost(false);
    } else if (!isLoading && hasMorePost) {
      setIsLoading(true);

      // Make a GET request to your API endpoint to fetch the next batch of 5 posts
      axios
        .get(
          // `http://localhost:4000/api/v1/posts?start=${startIndex}&count=${batchSize}`
          `https://pwnsters.unpwnable.io/api/v1/posts?start=${startIndex}&count=${batchSize}`
        )
        .then((response) => {
          setPosts([...posts, ...response.data.posts]);
          // setOtherPosts([...otherPosts, ...response.data.post]);
          setStartIndex(startIndex + batchSize);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
      // }, 1000);
    }
  };

  const handleScroll = () => {
    const section = document.getElementById("post-section");
    if (
      section &&
      window.innerHeight + window.scrollY >= document.body.offsetHeight
    ) {
      loadMorePosts();
    }
    if (
      postRef.current &&
      postRef.current.getBoundingClientRect().bottom <= window.innerHeight
    ) {
      loadMorePosts();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, postRef]);

  useEffect(() => {
    setIsLoading(true);

    // Make a GET request to your API endpoint to fetch the initial batch of 5 posts
    axios.get(
      // `http://localhost:4000/api/v1/posts`
      `https://pwnsters.unpwnable.io/api/v1/posts`
    ).then((response) => {
      setAllPosts(response.data.totalPostsCount);
    });
    axios
      .get(
        // `http://localhost:4000/api/v1/posts?start=${startIndex}&count=${batchSize}`
        `https://pwnsters.unpwnable.io/api/v1/posts?start=${startIndex}&count=${batchSize}`
      )
      .then((response) => {
        setPosts(response.data.posts);
        setStartIndex(startIndex + batchSize);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (posts.length > 0) {
      // Update FivePostsData with the first 5 posts from postsData
      setFiveLatesPosts(posts.slice(0, 5));
      setOtherPosts(posts.slice(5));
    }
  }, [posts]);

  return (
    <div className="min-h-[100vh] sm:p-16 p-8 bg-[#000] text-white font-WGetLinkFont">
      <a
        href="https://t.me/pwnsters"
        className="flex items-center hover:text-[#333]"
      >
        <img src={TelegramIcon} className="w-8" alt="telegram-icon"></img>
        <h2 className="font-bold text-3xl text-white ml-4 hover:text-[#49a2bb]">
          All News
        </h2>
      </a>
      <div className="container mx-auto">
        <FiveLatesPosts fivePostsData={fiveLatesPosts} />
      </div>
      <div id="post-section">
        <div className="lg:col-span-5 col-span-12 flex flex-col gap-y-5  my-10 overflow-x-hidden container mx-auto">
          {otherPosts.map((post, index) => (
            <div
              className="bg-[#42454d61] rounded-3xl border border-white p-0 sm:p-4 border-opacity-20 sm:flex gap-x-4 relative h-[500px] sm:h-auto"
              key={post._id}
              ref={index === otherPosts.length - 1 ? postRef : null}
            >
              <a
                href={
                  post.ogUrl
                    ? post.ogUrl
                    : post.url
                      ? post.url
                      : "https://pwnsters.unpwnable.io"
                }
              >
                <div className="relative sm:min-w-[120px] sm:max-w-[120px] opacity-100 transition-opacity duration-300 ease-in-out">
                  <span className="box-border block overflow-hidden w-auto h-auto bg-none opacity-100 border-0 m-0 p-0 relative">
                    <span className="box-border sm:block hidden w-auto h-auto bg-none opacity-100 border-0 m-0 p-0 pt-[100%]"></span>
                    <img
                      src={
                        post.image && !post.imgManuallyUploaded && !post.file
                          ? post.image
                          : post.image &&
                            (post.imgManuallyUploaded || post.file)
                            // ? `http://localhost:4000/api/v1/photos/${post.image}`
                            // : "https://placehold.co/600x400?text=No+Image"
                            ? `https://pwnsters.unpwnable.io/api/v1/photos/${post.image}`
                            : "https://placehold.co/600x400?text=No+Image"
                      }
                      alt=""
                      className="rounded-xl sm:absolute sm:top-0 sm:left-0 sm:bottom-0 smright-0 box-border p-0 border-none m-auto block min-w-full max-w-full   sm:min-h-full sm:max-h-full sm:h-[320px] h-[200px] object-cover"
                    ></img>
                  </span>
                </div>
              </a>
              <div className="flex flex-col p-4 sm:p-0">
                <a
                  href={
                    post.ogUrl
                      ? post.ogUrl
                      : post.url
                        ? post.url
                        : "https://pwnsters.unpwnable.io"
                  }
                >
                  <h3 className="font-bold text-xl mb-2 hover:text-[#5cbadf] transition-colors sm:line-clamp-1 line-clamp-3">
                    {post.title ? (
                      post.title
                    ) : post.context ? (
                      post.context
                    ) : (
                      <div className="break-all">
                        {post.url
                          ? post.url
                          : post.ogUrl
                            ? post.ogUrl
                            : "https://pwnsters.unpwnable.io"}
                      </div>
                    )}
                  </h3>
                </a>
                <p className="opacity-60 sm:line-clamp-1 mb-2 sm:p-0 line-clamp-2">
                  {post.file ? "This post has file " : <></>}
                  {post.description && post.context ? (
                    <>
                      {post.context}
                      <br />
                      {post.description}
                    </>
                  ) : post.context ? (
                    post.context
                  ) : post.description ? (
                    post.description
                  ) : (
                    "There is no description"
                  )}
                </p>
              </div>
              <div className="mt-auto flex md:justify-between md:items-center flex-col md:flex-row gap-y-2 md:gap-y-0 justify-start items-start p-4 sm:p-0">
                <div className="flex gap-x-2">
                  <a
                    href={
                      post.ogUrl
                        ? post.ogUrl
                        : post.url
                          ? post.url
                          : "https://pwnsters.unpwnable.io"
                    }
                  >
                    <span className="absolute right-10 bottom-3">
                      {post.domain
                        ? getHostnameFromUrl(post.domain)
                        : post.url
                          ? getHostnameFromUrl(post.url)
                          : post.ogUrl
                            ? getHostnameFromUrl(post.ogUrl)
                            : "https://pwnsters.unpwnable.io"}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        {isLoading && (
          <Box sx={{ display: "flex" }} className="w-full flex justify-center">
            <CircularProgress style={{ color: "#fff" }} />
          </Box>
        )}
      </div>
    </div>
  );
};

export default WShowPosts;
