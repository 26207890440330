import React, { useEffect, useState, useRef } from "react";
// import Post1 from "../assets/images/Unpwnable-Things-3D.png";
import Post1 from "../assets/images/pwnsters.png";
import Post3 from "../assets/images/post2.png";
import Post4 from "../assets/images/post3.png";
import Post2 from "../assets/images/post4.png";
import WNavigate from "./WNavigate";
import { motion } from "framer-motion";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { v4 as uuidv4 } from "uuid";
import TextSpan from "./TextSpan";

const WHeader = () => {
  const [indexOfStory, setIndexOfStory] = useState(0);
  const [activeLink, setActiveLink] = useState("");
  const [isIphone, setIsIphone] = useState(false);

  const [headerText, setHeaderText] = useState(
    "Pwnsters is public and community based part of unpwnable THINGS' threat intelligence platform (TIP)"
  );
  const [post, setPost] = useState(Post1);
  const [bgColor, setBgColor] = useState("#6479b7");
  const [hoverScroll, setHoverScroll] = useState(false);

  useEffect(() => {
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (iOS) {
      setIsIphone(true);
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1200) {
      setIsIphone(true);
    }
  }, []);

  const backgroundColors = [
    {
      id: uuidv4(),
      backColorPost: "#C3B59F",
      imgPost: Post1,
      textPost:
        "Pwnsters is public and community based part of unpwnable THINGS' threat intelligence platform (TIP)",
    },
    {
      id: uuidv4(),
      backColorPost: "#4a93b1",
      imgPost: Post2,
      textPost:
        "PWNSTERS' Cyber Threat Intelligence (CTI) service provides unpwnable THINGS with tailored threat intelligence and remediation guidance",
    },
    {
      id: uuidv4(),
      backColorPost: "#80B1FF",
      imgPost: Post3,
      textPost:
        "Mature security teams will rely on highly curated threat intelligence in addition to the newest cybersecurity technology as cyber threats continue to escalate in frequency and sophistication. ",
    },
    {
      id: uuidv4(),
      backColorPost: "#D6A2AD",
      imgPost: Post4,
      textPost:
        "The goal of cyber threat intelligence is to produce and disseminate information on the present state of the quickly changing cyber threat landscape",
    },
  ];

  //progressbar
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(100);
  const [isHoveredLeft, setIsHoveredLeft] = useState(false);
  const [isHoveredRight, setIsHoveredRight] = useState(false);

  const progressRef = useRef(() => { });
  const intervalRef = useRef(null); // Declare intervalRef using useRef

  const resetProgressBar = () => {
    setProgress(0);
  };

  const resetIntervalTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setIndexOfStory((prevIndex) => (prevIndex + 1) % 4);
    }, 7000);
  };

  useEffect(() => {
    progressRef.current = () => {
      if (progress >= 100) {
        resetProgressBar();
      } else {
        const diff = 10;
        setProgress(progress + diff);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 475);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setIndexOfStory((prevIndex) => (prevIndex + 1) % 4);
    }, 5000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    setBgColor(backgroundColors[indexOfStory].backColorPost);
    setPost(backgroundColors[indexOfStory]);
    setHeaderText(backgroundColors[indexOfStory].textPost);
    resetProgressBar(); // Reset progress bar when the story changes
    resetIntervalTimer();
  }, [indexOfStory]);

  const handleScrollSmothly = (e, link) => {
    e.preventDefault();
    setActiveLink(link);
    const targetElementCollaborative = document.getElementById(link);

    window.scroll({
      top: targetElementCollaborative.offsetTop,
      behavior: "smooth",
    });
  };

  const handleMouseEnterRight = () => {
    setIsHoveredRight(true);
  };

  const handleMouseLeaveRight = () => {
    setIsHoveredRight(false);
  };

  const handleMouseEnterLeft = () => {
    setIsHoveredLeft(true);
  };

  const handleMouseLeaveLeft = () => {
    setIsHoveredLeft(false);
  };

  const handleClickNext = () => {
    clearInterval(intervalRef.current); // Clear the current interval

    setIndexOfStory((prevIndex) => (prevIndex + 1) % 4);
  };

  const handleClickBefore = () => {
    clearInterval(intervalRef.current); // Clear the current interval

    setIndexOfStory((prevIndex) => {
      if (prevIndex === 0) {
        return 3;
      } else {
        return Math.abs(prevIndex - 1);
      }
    });
  };

  const opacityLeft = isHoveredLeft ? 0.5 : 1;
  const opacityRight = isHoveredRight ? 0.5 : 1;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="xl:h-[100vh] md:h-[130vh] sm:h-[135vh] h-[135vh] transition ease-in duration-500 z-20 relative "
      style={{ backgroundColor: bgColor }}
    >
      <WNavigate />
      <div className="lg:flex px-16 absolute top-[25vh] items-center sm:flex sm:flex-col sm:flex-nowrap lg:flex-row">
        <div className="xl:block tablet:hidden hidden">
          {/*<button className="p-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              onMouseEnter={handleMouseEnterRight}
              onMouseLeave={handleMouseLeaveRight}
              onClick={handleClickNext}
              style={{ transform: "rotate(180deg)", fillOpacity: opacityRight }}
            >
              <path fill="#191414" d="M9 18v-5l8 5V6l-8 5V6H7v12z"></path>
            </svg>
          </button>
          <button className="p-3 hover:text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              onMouseEnter={handleMouseEnterLeft}
              onMouseLeave={handleMouseLeaveLeft}
              onClick={handleClickBefore}
              style={{ fillOpacity: opacityLeft }}
            >
              <path fill="#191414" d="M9 18v-5l8 5V6l-8 5V6H7v12z"></path>
            </svg>
  </button>*/}
        </div>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.7 }}
          className="lg:w-2/5 xl:ml-32 md:w-full md:block sm:hidden hidden"
        >
          <h2 className="xl:text-3xl text-3xl font-bold xl:leading-[3.8rem] font-HeaderFont">
            {headerText.split(" ").map((letter, index) => (
              <TextSpan key={index}>{letter + "\u00A0"}</TextSpan>
            ))}
          </h2>
        </motion.div>
        <div className="lg:w-3/5 sm:w-full h-[50vh] sm:flex hidden sm:justify-center sm:items-center ">
          <motion.div
            initial={{ x: "100%", y: "0" }}
            transition={{ duration: 1 }}
            animate={{ x: "0", y: "0" }}
            className="lg:w-[30vw]  flex items-center lg:mx-24 sm:mx-auto sm:w-full justify-center md:mt-10 sm:mb-10"
          >
            <div className="sm:block lg:hidden hidden tablet:block ">
              <div className="mb-2">
                {/*<button className="p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    onMouseEnter={handleMouseEnterRight}
                    onMouseLeave={handleMouseLeaveRight}
                    onClick={handleClickNext}
                    style={{
                      transform: "rotate(180deg)",
                      fillOpacity: opacityRight,
                    }}
                  >
                    <path fill="#191414" d="M9 18v-5l8 5V6l-8 5V6H7v12z"></path>
                  </svg>
                  </button>*/}
              </div>
              <div>
                {/*<button className="p-3 hover:text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    onMouseEnter={handleMouseEnterLeft}
                    onMouseLeave={handleMouseLeaveLeft}
                    onClick={handleClickBefore}
                    style={{ fillOpacity: opacityLeft }}
                  >
                    <path fill="#191414" d="M9 18v-5l8 5V6l-8 5V6H7v12z"></path>
                  </svg>
                </button>*/}
              </div>
            </div>

            <motion.img
              key={post.id} // Add this key to force re-mount when post changes
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              src={post.imgPost}
              className="sm:h-[50vh] tablet:h-[50vh] h-[30vh]"
            ></motion.img>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.7 }}
          className="lg:w-2/5 xl:ml-32 sm:w-full md:hidden block tablet:mt-10"
        >
          <h2 className="xl:text-5xl lg:text-3xl font-bold xl:leading-[4rem] md:text-4xl sm:text-3xl tablet:text-3xl text-3xl font-HeaderFont">
            {headerText}
          </h2>
        </motion.div>
      </div>

      <div className=" w-[90vw]  mr-[5vw] absolute bottom-10 right-0">
        <div className="xl:hidden lg:block tablet:ml-[10vw] sm:hidden tablet:hidden block ">
          {/*<button className="px-3 hover:text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              onMouseEnter={handleMouseEnterLeft}
              onMouseLeave={handleMouseLeaveLeft}
              onClick={handleClickBefore}
              style={{ fillOpacity: opacityLeft }}
            >
              <path fill="#191414" d="M9 18v-5l8 5V6l-8 5V6H7v12z"></path>
            </svg>
          </button>
          <button className="px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              onMouseEnter={handleMouseEnterRight}
              onMouseLeave={handleMouseLeaveRight}
              onClick={handleClickNext}
              style={{ transform: "rotate(180deg)", fillOpacity: opacityRight }}
            >
              <path fill="#191414" d="M9 18v-5l8 5V6l-8 5V6H7v12z"></path>
            </svg>
            </button>*/}
        </div>
        <div className="flex justify-between">
          <motion.div
            initial={{ x: "-700px", y: "300px" }}
            animate={{ x: "0px", y: "0" }}
            transition={{ duration: 0.3 }}
            className="flex items-center lg:ml-32"
          >
            {/*
            <span className="font-bold">0{indexOfStory + 1}</span>
            <Box
              className="mx-3 w-[4vw] mt-2"
              sx={{
                "& .MuiLinearProgress-barColorPrimary": {
                  backgroundColor: "black",
                  height: "2px",
                },
                "& .MuiLinearProgress-bar2Buffer": {
                  height: "3px",
                  backgroundColor: "#d1d1d18f",
                },
                "& .MuiLinearProgress-dashed": {
                  width: "0%",
                },
              }}
            >
              <LinearProgress
                variant="buffer"
                value={progress}
                valueBuffer={buffer}
              />
            </Box>
            <span className="font-bold">04</span>
            */}
          </motion.div>
          <motion.a
            initial={{ x: "400px", y: "100px" }}
            animate={{ x: "0", y: "0" }}
            transition={{ duration: 0.3 }}
            href="#GetLink"
            onClick={(e) => handleScrollSmothly(e, "GetLink")}
            className="flex"
            onMouseEnter={() => setHoverScroll(true)}
            onMouseLeave={() => setHoverScroll(false)}
          >
            <span className="font-bold flex items-center px-3 font-HeaderFont">
              Enter a Threat
            </span>
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              className={`w-auto h-auto border border-[#3333335d] rounded-full p-4 ${hoverScroll ? "bg-[#333] text-white" : "text-black"
                }`}
            >
              <path
                stroke="currentColor"
                strokeWidth="1.4"
                d="M12 1v17M19 13l-7 7-7-7"
              ></path>
            </svg>
          </motion.a>
        </div>
      </div>
    </motion.div>
  );
};

export default WHeader;
