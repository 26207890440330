import React, { useState, useEffect } from "react";

const FiveLatesPosts = ({ fivePostsData }) => {
  const [firstPost, setFirstPost] = useState(null);
  const [otherPosts, setOtherPosts] = useState(null);

  const getHostnameFromUrl = (urlString) => {
    try {
      if (urlString.includes("http://") || urlString.includes("https://")) {
      const url = new URL(urlString);
      return url.hostname;
    } else {
      return urlString;
    }
    } catch (error) {
      console.error('Invalid URL:', urlString);
      return '';
    }
  };

  useEffect(() => {
    if (fivePostsData.length > 0) {
      setFirstPost(fivePostsData[0]);
      setOtherPosts(fivePostsData.slice(1, 5));
    }
  }, [fivePostsData]);

  return (
    <div className="grid grid-cols-12 mt-12 gap-y-4 md:gap-x-8 lg:gap-y-0 text-white">
      {/* that big post in the left */}
      {firstPost ? (
        <div className="lg:col-span-7 col-span-12">
          <div
            className="bg-[#42454d61] rounded-3xl border border-white border-opacity-20 sm:p-4 justify-center md:h-[680px] h-[500px] relative"
            key={firstPost._id}
          >
            <a
              href={
                firstPost.ogUrl
                  ? firstPost.ogUrl
                  : firstPost.url
                    ? firstPost.url
                    : "htttps://pwnsters.unpwnable.io"
              }
            >
              <div className="relative rounded-3xl overflow-hidden mb-4 opacity-100 transition-opacity duration-300 ease-in-out delay-0">
                <span className="box-border block overflow-hidden w-auto h-auto bg-none opacity-100 border-0 m-0 p-0 relative">
                  <img
                    src={
                      firstPost.image &&
                        !firstPost.imgManuallyUploaded &&
                        !firstPost.file
                        ? firstPost.image
                        : firstPost.image &&
                          (firstPost.imgManuallyUploaded || firstPost.file)
                          // ? `http://localhost:4000/api/v1/photos/${firstPost.image}`
                          // : "https://placehold.co/600x400?text=No+Image"
                          ? `htttps://pwnsters.unpwnable.io/api/v1/photos/${firstPost.image}`
                          : "https://placehold.co/600x400?text=No+Image"
                    }
                    alt=""
                    className="hover:scale-105 duration-500 box-border p-0 border-0 mx-auto block min-w-full max-w-full sm:h-[320px] h-[200px] object-cover"
                  ></img>
                </span>
              </div>
            </a>
            <a
              href={
                firstPost.ogUrl
                  ? firstPost.ogUrl
                  : firstPost.url
                    ? firstPost.url
                    : "htttps://pwnsters.unpwnable.io"
              }
            >
              <h2 className="font-bold my-2 hover:text-[#5cbadf] transition-colors sm:p-0 px-4 text-2xl sm:line-clamp-3 line-clamp-3">
                {firstPost.title ? (
                  firstPost.title
                ) : firstPost.context ? (
                  firstPost.context
                ) : (
                  <div className="break-all">
                    {firstPost.url
                      ? firstPost.url
                      : firstPost.ogUrl
                        ? firstPost.ogUrl
                        : "htttps://pwnsters.unpwnable.io"}
                  </div>
                )}
              </h2>
            </a>
            <p className="opacity-60 text-sm  sm:p-0 px-4 line-clamp-2">
              {firstPost.file ? "This post has file " : <></>}
              {firstPost.description && firstPost.context ? (
                <>
                  {firstPost.context}
                  <br />
                  {firstPost.description}
                </>
              ) : firstPost.context ? (
                firstPost.context
              ) : firstPost.description ? (
                firstPost.description
              ) : (
                "There is no description"
              )}
            </p>
            <div className="flex justify-between items-center mt-20  sm:p-0">
              <div className="flex gap-x-2">
                <a
                  href={
                    firstPost.ogUrl
                      ? firstPost.ogUrl
                      : firstPost.url
                        ? firstPost.url
                        : "htttps://pwnsters.unpwnable.io"
                  }
                >
                  <span className="absolute right-10 bottom-3">
                    {firstPost.domain
                      ? getHostnameFromUrl(firstPost.domain)
                      : firstPost.ogUrl
                        ? getHostnameFromUrl(firstPost.ogUrl)
                        : firstPost.url
                          ? getHostnameFromUrl(firstPost.url)
                          : "htttps://pwnsters.unpwnable.io"}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        "...loading"
      )}

      {/* that 3 posts in the right */}
      {firstPost && otherPosts.length > 0 ? (
        <div className="lg:col-span-5 col-span-12 flex flex-col gap-y-5 overflow-x-hidden">
          {otherPosts.map((post) => (
            <div
              className="bg-[#42454d61] rounded-3xl border border-white border-opacity-20  sm:flex gap-x-4 sm:p-4 relative h-[500px] sm:h-auto"
              key={post._id}
            >
              <a
                href={
                  post.ogUrl
                    ? post.ogUrl
                    : post.url
                      ? post.url
                      : "htttps://pwnsters.unpwnable.io"
                }
              >
                <div className="relative sm:min-w-[120px] sm:max-w-[120px] opacity-100 transition-opacity duration-300 ease-in-out">
                  <span className="box-border block overflow-hidden w-auto h-auto bg-none opacity-100 border-0 m-0 p-0 relative">
                    <span className="box-border sm:block hidden w-auto h-auto bg-none opacity-100 border-0 m-0 p-0 pt-[100%] "></span>
                    <img
                      src={
                        post.image && !post.imgManuallyUploaded && !post.file
                          ? post.image
                          : post.image &&
                            (post.imgManuallyUploaded || post.file)
                            // ? `http://localhost:4000/api/v1/photos/${post.image}`
                            // : "https://placehold.co/600x400?text=No+Image"
                            ? `htttps://pwnsters.unpwnable.io/api/v1/photos/${post.image}`
                            : "https://placehold.co/600x400?text=No+Image"
                      }
                      alt=""
                      className="rounded-xl sm:absolute sm:top-0 sm:left-0 sm:bottom-0 sm:right-0 box-border p-0 border-none m-auto block min-w-full max-w-full  sm:min-h-full sm:max-h-full sm:h-[320px] h-[200px] object-cover"
                    ></img>
                  </span>
                </div>
              </a>
              <div className="flex flex-col p-4 sm:p-0">
                <a
                  href={
                    post.ogUrl
                      ? post.ogUrl
                      : post.url
                        ? post.url
                        : "htttps://pwnsters.unpwnable.io"
                  }
                >
                  <h3 className="font-bold text-xl mb-2 hover:text-[#5cbadf] transition-colors sm:line-clamp-2 line-clamp-3">
                    {post.title ? (
                      post.title
                    ) : post.context ? (
                      post.context
                    ) : (
                      <div className="break-all">
                        {post.url
                          ? post.url
                          : post.ogUrl
                            ? post.ogUrl
                            : "htttps://pwnsters.unpwnable.io"}
                      </div>
                    )}
                  </h3>
                </a>
                <p className="opacity-60 sm:line-clamp-1 mb-2 px-4 mt-4 sm:p-0 line-clamp-2">
                  {post.file ? "This post has file " : <></>}
                  {post.description && post.context ? (
                    <>
                      {post.context}
                      <br />
                      {post.description}
                    </>
                  ) : post.context ? (
                    post.context
                  ) : post.description ? (
                    post.description
                  ) : (
                    "There is no description"
                  )}
                </p>
              </div>
              <div className="mt-auto flex md:justify-between md:items-center flex-col md:flex-row gap-y-2 md:gap-y-0 justify-start items-start p-4 sm:p-0">
                <div className="flex gap-x-2">
                  <a
                    href={
                      post.ogUrl
                        ? post.ogUrl
                        : post.url
                          ? post.url
                          : "htttps://pwnsters.unpwnable.io"
                    }
                  >
                    <span className="absolute right-10 bottom-3">
                      {post.domain
                        ? getHostnameFromUrl(post.domain)
                        : post.ogUrl
                          ? getHostnameFromUrl(post.ogUrl)
                          : post.url
                            ? getHostnameFromUrl(post.url)
                            : "htttps://pwnsters.unpwnable.io"}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FiveLatesPosts;
