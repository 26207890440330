import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom/client
import App from "./App";
import WNotFound from "./components/WNotFound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./styles/App.css";

const root = createRoot(document.getElementById("root")); // Use createRoot from react-dom/client
root.render(
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="*" element={<WNotFound />} />
      </Routes>
    </Router>
);
